<mat-toolbar>
    <button mat-icon-button (click)="print()">
        <mat-icon>print</mat-icon>
    </button>
</mat-toolbar>
<div class="d-flex flex-wrap mb-3 justify-content-center sticker mt-3" id="section-to-print">
    <div class="ml-2 d-flex w-100 justify-content-between align-items-center relative">
        <div>
            <h2 class="m-0">{{data.student.Name}} {{data.student.Surname}}</h2>
            <h3 *ngIf="data.student.Group" class="m-0">{{data.student.Group.name}}</h3>
            <h3 *ngIf="data.student.DOB" class="m-0">{{data.student.DOB}}</h3>
            <h3 *ngIf="parent1" class="m-0">{{parent1.Name}}: {{parent1.Mobile}}</h3>
            <h3 *ngIf="parent2 && (parent1?.Name !== parent2.Name)" class="m-0">{{parent2.Name}}: {{parent2.Mobile}}</h3>
            <mat-spinner *ngIf="(data.student.ParentID1 && !parent1)" style="margin: auto" color="primary" diameter="20"></mat-spinner>
        </div>

            <img style="position: absolute; bottom: 20px; right: 0px;" width="80px" src="./../../../assets/icon_kinders copy.png">

    </div>
  </div>
import { FileUpload } from "./file-upload";

export interface Member{
        id?: string;
        Surname: string;
        Name: string;
        NickName?: string;
        Title?: string;
        Initials?: string;
        Gender?: string;
        DOB?: string;
        ID: string;
        Address?: string;
        PostalAddress?: string;
        Mobile?: string;
        Phone?: string;
        Email?: string;
        isMainContact?: boolean;
        MemberStatus?: string;
        MaritalStatus?: string;
        MariageDate?: string;
        PartnerID?: string;
        Occupation?: string;
        InformedConsent?: boolean;
        MedicalAidName?: string;
        MedicalAidNumber?: string;
        MedicalAidMainMember?: string;
        Notes?: string;
        AccountID?: string;
        Document?: FileUpload;
        DriveId?: string;
        Children?: any[];
        InvolvedIn?: any[];
        Role?: string;
}

export interface MemberConsent{
        ID: string;
        InformedConsent: boolean;
        Document?: FileUpload;
}

export interface MedicalDetails{
        ID: string;
        HealthProblems: string;
        Notes: string;
        MedicalAidName: string;
        MedicalAidNumber: string;
        MedicalAidMainMember: string;
        InformedFinancialConsent?: boolean;
        InformedTreatmentConsent?: boolean;
}

export interface MemberSearchCriteria {name: string, surname: string, mobile: string, email: string}
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Student, StudentConsent } from 'backend/interfaces/student.interface';
import { Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { StudentService } from 'src/app/admin/services/student.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { ParentDataService } from 'src/app/parent/services/data.service';
import { GroupService } from 'src/app/admin/services/group.service';
import { Group } from 'backend/interfaces/group.interface';
import { faEye, faUser } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { Member, MemberConsent } from 'backend/interfaces/member.interface';
import { map } from 'rxjs/operators';
import { SidenavActions, SideNavService } from 'src/app/admin/services/side-nav.service';

@Component({
  selector: 'app-student-profile',
  templateUrl: './student-profile.component.html',
  styleUrls: ['./student-profile.component.scss']
})
export class StudentProfileComponent implements OnInit {
  @Input() isAdmin = false;
  @Input() isStudent = false;
  @Input() profile: Student;
  studentClass: Group;
  sideNavActions = SidenavActions;
  groups;
  loading = false;
  parent: Member;
  faView = faEye;
  faUser = faUser;
  currentUser;
  @Output() emitProfile: EventEmitter<any> = new EventEmitter();
  @Output() emitMedical = new EventEmitter<Object>();
  @ViewChild('stepper') private stepper: MatStepper;

  constructor(private authService: AuthService, private dataService: ParentDataService, private fb: UntypedFormBuilder, private breakpointObserver: BreakpointObserver, private studentService: StudentService, private router: Router, private _snackBar: MatSnackBar, private groupService: GroupService, private sidenav: SideNavService) {

    this.stepperOrientation = breakpointObserver.observe('(min-width: 800px)')
      .pipe(map(({ matches }) => matches ? 'horizontal' : 'vertical'));
    this.authService.$User.subscribe((res) => {
      this.isAdmin = res?.get('role') === 'admin';
      this.currentUser = res;
    })
    this.authService.$User.subscribe((res) => {
      this.isAdmin = res?.get('role') === 'admin';
      this.currentUser = res;
    })
  }
  profileForm: UntypedFormGroup;
  medicalForm: UntypedFormGroup;
  thirdFormGroup = this.fb.group({
    thirdCtrl: ['', Validators.required]
  });
  stepperOrientation: Observable<StepperOrientation>;
  dataLoaded: Promise<boolean>;
  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.profileForm = this.fb.group({
      ParentID1: ['',Validators.length > 10],
      ParentID2: ['', Validators.length > 10],
      ParentName: [''],
      ParentContact: ['', Validators.length > 9],
      Name: ['', Validators.required],
      Surname: ['', Validators.required],
      ID: ['', Validators.length > 6],
      Gender: [''],
      Notes: [''],
      RegistrationPaid: [false],
      SpecialNeeds: [false],
      DOB: ['', Validators.required],
      Group: [undefined]
    });
    this.profileForm.reset();
    this.studentService.activatedStudent$.subscribe(async res => {
      this.studentClass = res?.Group;
      if(res){
      this.profile = res;
      if((res.DOB === '' || !res.DOB) && res.ID)
        {
          if(res.ID.length > 10){
          const yy = res.ID.substring(0,2)
          const mm = res.ID.substring(2,4)
          const dd = res.ID.substring(4,6)
          console.log(mm+'/'+dd+'/'+yy)
          this.profile.DOB = moment(new Date(mm+'/'+dd+'/'+yy)).format('MMM DD yyyy');
          }
        }
      }else{
        this.profile = {
          ParentID1: this.currentUser.get('ID'),
          ParentID2: '',
          ParentName: '',
          ParentContact: '',
          Name: '',
          Surname: '',
          ID: '',
          Title: '',
          Gender: '',
          HealthProblems: '',
          Notes: '',
          SpecialNeeds: false,
          RegistrationPaid: false,
          Group: null,
          DOB: ''
        }
      }

        this.profileForm.patchValue({
          ParentID1: this.profile.ParentID1 !== '' ? this.profile.ParentID1 : this.currentUser.get('ID'),
          ParentID2: this.profile.ParentID2,
          ParentName:this.profile.ParentName,
          ParentContact: this.profile.ParentContact,
          Name: this.profile.Name,
          Surname: this.profile.Surname,
          ID: this.profile.ID,
          Gender: this.profile.Gender,
          Notes: this.profile.Notes,
          Group: this.studentClass,
          RegistrationPaid: this.profile.RegistrationPaid,
          SpecialNeeds: this.profile.SpecialNeeds,
          DOB: new Date(this.profile.DOB)
        });

    });
    this.groupService.groups$.subscribe(async groups => {
      this.groups = groups;
    });

    this.dataLoaded = Promise.resolve(true);
    this.loading = false;
  }
  async saveProfile() {
    if (this.profileForm.dirty) {
      this.loading = true;
      this.studentService
        .saveStudent({
          id: this.profile.id,
          ParentID1: this.profileForm.controls['ParentID1'].value,
          ParentID2: this.profileForm.controls['ParentID2'].value,
          ParentName: this.profileForm.controls['ParentName'].value,
          ParentContact: this.profileForm.controls['ParentContact'].value,
          Name: this.profileForm.controls['Name'].value,
          Surname: this.profileForm.controls['Surname'].value,
          ID: this.profileForm.controls['ID'].value,
          Gender: this.profileForm.controls['Gender'].value,
          Notes: this.profileForm.controls['Notes'].value,
          SpecialNeeds: this.profileForm.controls['SpecialNeeds'].value,
          RegistrationPaid: this.profileForm.controls['RegistrationPaid'].value,
          Group: this.profileForm.controls['Group'].value,
          DOB: moment(this.profileForm.controls['DOB'].value).format('MMM DD yyyy').toString()
        })
        .then((res) => {
          this.loading = true;
          this.profile = res;
          this.studentService.activatedStudent.next(res);
          this.studentService.newStudentEmail = undefined;
          this.openSnackBar('Leerder gestoor');
          // this.emitProfile.emit(this.profile);
          console.log(this.isStudent)
          if(this.isAdmin){
            this.openSnackBar('Leerder gestoor.')
            this.sidenav.close();
            this.loading = false;
          }else if(this.isStudent){
            this.dataService.updateUserRole(this.currentUser.id, 'student', this.profile.id).then(res => {
              this.openSnackBar('Profiel opgedateer.')
              this.sidenav.close();
              this.emitProfile.emit(this.profile);
              this.loading = false;
            }).catch(err => this.openSnackBar(err))
          }else{
            this.loading = false;
            this.sidenav.open(this.sideNavActions.StudentConsent);
          }
        })
        .catch((err) => {
          this.openSnackBar(err);
          this.loading = false;
        });
    } else {
      this.emitProfile.emit(this.profile);
    }
  }
  async saveMedicalDetails() {
    if (this.medicalForm.dirty) {
      this.loading = true;
      const medicalForm = {
        ...this.medicalForm.value,
        ID: this.profile.ID
      };

      this.studentService
        .saveStudentMedicalDetails(medicalForm)
        .then(() => {
          this.openSnackBar('Medical details saved');
          this.loading = false;
          // this.stepper.next();
        })
        .catch((err) => {
          this.openSnackBar(err);
          this.loading = false;
        });
      this.medicalForm.markAsPristine();
    } else {
      this.emitProfile.emit(this.profile);
    }
  }
  getErrorMessage() {
    if (this.profileForm.controls['Name'].hasError('required')) {
      return 'You must enter a value';
    }

    return this.profileForm.controls['Name'].hasError('required') ? 'Not a valid name' : '';
  }

  async saveStudentConsent(event: { student: StudentConsent, signature: string }) {
    this.loading = true;
    this.studentService
      .saveStudentConsent({...event.student, email: this.currentUser.get('username')})
      .then(() => {
        this.openSnackBar("Vrywaring gestoor. U sal per epos 'n kopie ontvang");
        this.loading = false;
        this.emitProfile.emit(event.student);
      })
      .catch((err) => {
        this.openSnackBar(err);
        this.loading = false;
      });
  }


  back() {
    this.stepper.previous();
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }

  viewParent(id: string) {
    this.router.navigate(['admin', 'member', id])
  }

  selectDate(e: any) {
    this.profileForm.patchValue({ 'DOB': e.value.toDateString() });
  }

}



